@import url("https://fonts.googleapis.com/css?family=Montserrat:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:100,100i,300,300i,400,400i,600,600i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

.dark-bg  {
  background-color: #000;
}

.navbar-floating {
  position: absolute;
  top: 0;
  left: 0;
}

.card-link {
  text-decoration: none;
}
/* Card */
$card-bg: #f8f8f8;
$card-border-width: 0;

.card {
  line-height: 25.2px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 25px;
  letter-spacing: 0.32px;
  margin-bottom: 30px;
}

.card-title {
  color: #55565b;
  letter-spacing: 2.2864px;
  font-weight: 400;
  line-height: 1.5em;
  padding-bottom: 15px;
}

.card-text {
  color: #575757;
  font-size: 0.75rem;
  line-height: 2;
}

.card-icon {
  font-size: 45px;
  margin-bottom: 25px;
}

.card-link {
  color: #212121;
  font-size: 16px !important;
  margin-top: 30px;
}

.title {
  font-size: 13px !important;
}

.title-juri {
  font-size: 11px !important;
  height: 50px;
}

.error-message,
.hackathon-topics {
  color: red;
}

.success-message {
  color: green;
}

.hackathon-topics {
  color: red;
  font-size: 14px;
  font-weight: 500;
}

/* Accordion */
$accordion-button-bg: #ececec;
$accordion-button-color: #212121;
$accordion-button-focus-border-color: #212121;
$accordion-button-active-bg: #ececec;
$accordion-button-active-color: #212121;

.accordion-button {
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  line-height: 1.6 !important;
  outline: none !important;
  box-shadow: none !important;
}

.accordion-button:active,
.accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.accordion-item {
  margin-bottom: 0.625rem !important;
}

.accordion-body {
  font-size: 0.9rem;
  color: #575757;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%), 0 4px 12px 0 rgb(0 0 0 / 10%);
}

/* Background */
$body-bg: #fff;

/* Border Radius */
$enable-rounded: true;
$border-radius: 3px;

/* Footer */
.footer-apply-button {
  border-radius: 0px !important;
  border: 0.1429rem solid #212121 !important;
  background: none;
  line-height: 1.2;
  padding: 0.9rem 1.071rem 0.7rem !important;
  color: #55565b;
}

.footer-apply-button:hover {
  border-radius: 0px !important;
  border: 0.1429rem solid #212121 !important;
  color: #fff;
  background: #212121 !important;
}

/* GDPR */
.gdpr-text {
  font-size: 12px !important;
  color: #575757;
  font-weight: 400;
}

/* CV Upload */
.cv-upload-button {
  background-color: #000;
  color: #fff;
}

/* Input */
$input-border-color: #f1f1f1;
$input-border-width: 0.1429rem;
$input-border-radius: 0;
$input-focus-border-color: #c9cacd;
$input-color: #55565b;
$input-font-size: 0.875rem;
$input-font-weight: 500;
$input-focus-box-shadow: none;

$form-label-font-size: 16px;

/* btn-apply */
.btn-apply {
  background: #5645ff !important;
  border-color: #5645ff !important;
  color: #fff !important;
  padding-right: 2rem !important;
  padding-left: 1rem !important;
  font-size: 1.125rem !important;
  padding: 0.9375rem !important;
  letter-spacing: 0.1429rem !important;
  text-transform: uppercase;
  border: 0.1429rem solid #5645ff !important;
  cursor: pointer;
  font-weight: 500;
  border-radius: 0 !important;
  min-width: 16.9rem;
}

/* Form Check */
.form-check-label {
  font-size: 00.875rem !important;
  font-weight: 400 !important;
}
$form-check-input-border-radius: 50%;
$form-check-input-checked-bg-color: #5645ff;

.form-wrapper {
  @media (min-width: 992px) {
    width: 1000px !important;
    position: relative;
    left: calc(50% - 500px);
  }
}

/* Right Icon */
.right-icon {
  width: 100%;
  font-size: 86px;
}

/* Prize */
.prize-text {
  font-weight: 500;
  font-size: 22px;
}

/* Backlink */
.back-link,
.back-link > a {
  color: #000 !important;
  font-weight: 500;
}
.back-link:hover {
  background-color: #f8f8f8;
}

/* HR */
hr {
  border-top: 0.0625 rem #ebedef solid !important;
}

/* Footer */
.footer-xathon-logo {
  max-height: 40px !important;
  max-width: 100px !important;
}

.footer-xathon-text {
  padding-top: 16px;
  font-weight: 500;
}

.sub-footer-black-bg {
  background-color: #212121;
}

/* KVKK */
.kvkk-text {
  font-weight: 400;
  font-size: 14px;
}

.required-field {
  color: red !important;
  font-size: 1.125rem !important;
}

.topic-description {
  @media (min-width: 992px) {
    height: 160px;
  }
}

.topic-title {
  @media (min-width: 992px) {
    height: 80px;
  }
}

.topic-card {
  height: 100%;
}

.mentor-card {
  height: 100%;
}

.jury-card {
  @media (min-width: 768px) {
    height: 100%;
  }
}

/* Font Family */
$font-family-base: "Poppins", sans-serif;

/* Font */
$h1-font-size: 1.625rem;
$h2-font-size: 3rem;
$h3-font-size: 1.25rem;
$h4-font-size: 1;
$h5-font-size: 1rem;
$h6-font-size: 1rem;
$font-size-base: 1.125rem;
$small-font-size: 0.75rem;
$lead-font-weight: 400;
$initialism-font-size: $small-font-size;
$headings-font-family: $font-family-base;
$headings-font-weight: 700;

$nav-link-color: #fff;

@import "bootstrap";
